import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import styled, { useTheme, css } from 'styled-components';

import { SALES_ORDER_STATUS, SHIPMENT_STATUS } from '../../Domain/Payment/constants';
import { Flex, Text, Box } from '../Base';

import { PURCHASE_STATUS, PurchaseHistoryProps } from './types';
import PurchaseHistoryDetail from './PurchaseHistoryDetail';
import { get } from '../Theme/constants';
import { CURRENCY_TYPE } from '../constants';

const PaymentSummaryWrapper = styled(Flex)`
  position: relative;
  max-width: 1000px;
  width: 100%;
  flex-direction: column;
  background-color: ${get('colors.background.card')};
  border-radius: 8px;
  border: 1px solid ${get('colors.border.purchaseHistoryCard')};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
`;

const StatusTag = styled((props) => (
  <Text
    py={1}
    px={2}
    borderRadius="large"
    color="white"
    textStyle="caption"
    textAlign="center"
    {...props}
  />
))`
  border-radius: 40px;
  font-weight: ${get('fontWeights.semibold')};
`;

const ProductImage = styled.img`
  max-width: 100%;
  width: 102px;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      width: 100%;
    }
  `}
`;

function mapStatus(status: SALES_ORDER_STATUS) {
  switch (status) {
    case SALES_ORDER_STATUS.PENDING:
      return PURCHASE_STATUS.PENDING;
    case SALES_ORDER_STATUS.SUCCESS:
    case SALES_ORDER_STATUS.PAID:
      return PURCHASE_STATUS.SUCCESS;
    case SALES_ORDER_STATUS.FAILED:
    case SALES_ORDER_STATUS.REJECTED:
    case SALES_ORDER_STATUS.VOID:
      return PURCHASE_STATUS.FAILED;
  }
}

const statusTextMapper: { [key in PURCHASE_STATUS]: string } = {
  [PURCHASE_STATUS.SUCCESS]: 'purchase_status_success',
  [PURCHASE_STATUS.FAILED]: 'purchase_status_failed',
  [PURCHASE_STATUS.PENDING]: 'purchase_status_pending',
};

const PurchaseHistoryCard = (props: PurchaseHistoryProps): JSX.Element => {
  const { colors } = useTheme();
  const { t } = useTranslation(['purchase-history', 'translation', 'common']);

  const TAG_COLORS = {
    [PURCHASE_STATUS.SUCCESS]: colors.system.success['500'],
    [PURCHASE_STATUS.FAILED]: colors.system.danger['500'],
    [PURCHASE_STATUS.PENDING]: colors.system.warning['500'],
  };

  const BACKGROUND_COLORS = {
    [PURCHASE_STATUS.SUCCESS]: colors.background.purchaseHistoryCardHeader.success,
    [PURCHASE_STATUS.FAILED]: colors.background.purchaseHistoryCardHeader.failed,
    [PURCHASE_STATUS.PENDING]: colors.background.purchaseHistoryCardHeader.pending,
  };

  const { orderNo, status, price, product, shipment, currency } = props;
  const purchasedStatus = mapStatus(status);
  const isShipped =
    shipment &&
    (shipment.status === SHIPMENT_STATUS.DELIVERED ||
      shipment.status === SHIPMENT_STATUS.SHIPPED ||
      shipment.status === SHIPMENT_STATUS.RETURN);

  const currencyText =
    currency === CURRENCY_TYPE.THB ? t('common:currency_thb') : t('common:currency_usd');

  return (
    <PaymentSummaryWrapper>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        px={4}
        py={2}
        bg={BACKGROUND_COLORS[purchasedStatus]}
        borderTopLeftRadius={8}
        borderTopRightRadius={8}
      >
        <Text textStyle="body1" color="text.darkText">
          {t('purchase_history_card_order_number')} : {orderNo}
        </Text>
        <StatusTag bg={TAG_COLORS[purchasedStatus]}>
          {t(statusTextMapper[purchasedStatus])}
          {isShipped && (
            <Text as="span" textStyle="caption" ml={1}>
              ({t('purchase_history_card_status_shipped')})
            </Text>
          )}
        </StatusTag>
      </Flex>
      <Flex flexDirection={{ _: 'column', sm: 'row' }} flexGap={5} p={6} color="text.darkText">
        <Flex alignItems="flex-start" width={{ _: '100%', sm: '180px' }} flexShrink="0">
          <ProductImage src={product.cardImageUrl} alt={product.title} />
          <Text textStyle="body1Medium" display={{ _: 'block', sm: 'none' }} ml={5}>
            {product.title}
          </Text>
        </Flex>
        <Box flexGrow={1} pl={{ _: 0, sm: 5 }}>
          <PurchaseHistoryDetail {...props} />
        </Box>
      </Flex>
      <Flex
        justifyContent="space-between"
        px={4}
        py={2}
        borderTop="1px solid"
        borderColor="border.purchaseHistoryCard"
        color="text.darkText"
      >
        <Text textStyle="body1">{t('purchase_history_card_amount')}</Text>
        <Text textStyle="subtitle1" color="text.redText">
          {numeral(price).format('0,0.00')}
          <Text as="span" textStyle="body1" ml={1} color="text.darkText">
            {currencyText}
          </Text>
        </Text>
      </Flex>
    </PaymentSummaryWrapper>
  );
};

export default PurchaseHistoryCard;
