import { useContext, createContext, useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { sendDataToGTM } from '../../Utils/analytics/googleTagManager';
import goToLoginPage from '../../Utils/goToLoginPage';

import {
  CUSTOMER_TYPE,
  SHIPMENT_COUNTRY,
  PAYMENT_METHOD,
  SKU_CATEGORY,
  PaymentProviderProps,
  IPaymentContext,
  PurchaseDetailData,
  handlePurchaseOrderInput,
  handlePurchaseInput,
} from './PaymentProvider.d';
import {
  isContainShipmentProduct,
  getWorkshopEventTimeText,
  isVirtualWorkshop,
  validateSKUStatus,
} from './Utils';

import { useConfig } from '../ConfigContext';
import usePaymentPreSalesOrder from '../../Domain/usePaymentPreSalesOrder';
import { SFFoPlaceOrderWithOfflineTransactionInputProps } from '../../Domain/usePurchaseOrder.d';
import usePurchaseOrder from '../../Domain/usePurchaseOrder';
import useQuickRegister from '../../Domain/useQuickRegister/useQuickRegister';
import { QUICK_REGISTER_RESPONSE_STATUS } from '../../Domain/useQuickRegister/types';
import { useAuth } from '../../Contexts/AuthContext';
import {
  mapGTMPaymentMethodValue,
  mapGTMProductTypeValue,
  mapGAEventCategoryValue,
} from '../../Utils/analytics/mapping';
import { formatAddress } from './Utils/formatAddress';

const PaymentContext = createContext<IPaymentContext | undefined>(undefined);

const initialPurchaseDetailData: PurchaseDetailData = {
  isShipmentPackage: false,
  taxInvoiceRequested: false,
  useTaxInvoiceAddressAsShipmentAddress: true,
};

const PaymentProvider = ({ children }: PaymentProviderProps): JSX.Element => {
  const navigate = useNavigate();
  const { skuCode } = useParams<{ skuCode: string }>();
  const {
    loginUrl,
    thankYouPageURL,
    storeURL,
    enableAnonymousUser,
    userConsent,
    discountCodeParamKey = '',
  } = useConfig();
  const { userInfo, isLoggedIn } = useAuth();
  const { handleQuickRegister } = useQuickRegister();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isInvalidDiscountCode, setIsInvalidDiscountCode] = useState(false);

  const discountCodeParamValue = searchParams.get(discountCodeParamKey) ?? undefined;
  const [isDiscountCodeValidating, setIsDiscountCodeValidating] = useState(false);
  const [currentDiscountCode, setCurrentDiscountCode] = useState<string | undefined>(
    discountCodeParamValue
  );

  const [isPreSalesOrderProcessing, setIsPreSalesOrderProcessing] = useState(true);
  const [purchaseDetailData, setPurchaseDetailData] = useState(initialPurchaseDetailData);
  const [currentCustomerType, setCurrentCustomerType] = useState<CUSTOMER_TYPE>(
    CUSTOMER_TYPE.INDIVIDUAL
  );
  const [taxInvoiceRequested, setTaxInvoiceRequested] = useState<boolean>(false);
  const [useTaxInvoiceAddressAsShipmentAddress, setUseTaxInvoiceAddressAsShipmentAddress] =
    useState<boolean>(true);
  const [currentProductQuantity, setCurrentProductQuantity] = useState(1);

  const { paymentPreSalesOrder, loading: isPaymentPreSalesOrderUpdating } =
    usePaymentPreSalesOrder();
  const {
    purchaseOrder,
    isCoursePurchasing,
    isPurchaseOrderFailed,
    setIsPurchaseOrderFailed,
    clearPurchaseOrderStatus,
    purchaseOrderFailedMessage,
  } = usePurchaseOrder({
    onError: (error) => {
      // TODO: temporary error handling, please revise this.
      console.error(`Purchase error: ${error}`);
    },
  });

  const updatePurchaseDetailData = (purchaseDetailData: Partial<PurchaseDetailData>) => {
    setPurchaseDetailData((prevPurchaseDetailData) => ({
      ...prevPurchaseDetailData,
      ...purchaseDetailData,
    }));
  };

  const handleOnPurchaseIsSuccessful = (
    orderDetail: SFFoPlaceOrderWithOfflineTransactionInputProps
  ) => {
    const { customerType, discountCode, paymentMethod } = orderDetail;

    const conversionValue =
      customerType === CUSTOMER_TYPE.CORPORATE
        ? purchaseDetailData?.paymentAmount
        : purchaseDetailData?.totalAmountExclVAT;

    sendDataToGTM({
      event: 'purchase-button-click',
      skuCode,
      customerType,
      discountCode,
      productTitle: purchaseDetailData?.courseTitle,
      conversionValue,
      productType: mapGTMProductTypeValue(purchaseDetailData?.productType),
      paymentMethod: mapGTMPaymentMethodValue(paymentMethod),
      gaEventCategory: mapGAEventCategoryValue({
        productType: purchaseDetailData?.productType,
        productTitle: purchaseDetailData?.courseTitle,
        skuCode: skuCode,
      }),
    });

    sendDataToGTM({ ecommerce: null });
    sendDataToGTM({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'THB',
        value: conversionValue,
        items: [
          {
            item_id: skuCode,
            item_name: purchaseDetailData?.courseTitle,
            discount: purchaseDetailData?.discountInclVAT,
            price: conversionValue,
            item_category: mapGTMProductTypeValue(purchaseDetailData?.productType),
          },
        ],
      },
    });
  };

  const handlePaymentPreSalesOrder = async (customerType?: CUSTOMER_TYPE, quantity?: number) => {
    try {
      const { data } = await paymentPreSalesOrder({
        preSalesOrderItems: [
          { SKUCode: skuCode as string, quantity: quantity ?? currentProductQuantity },
        ],
        customerType: customerType ?? currentCustomerType,
        discountCode: currentDiscountCode,
      });

      const paymentUpsertPreSalesOrderData = data?.sfFoPaymentUpsertPreSalesOrder ?? {};

      const {
        preSalesOrderItems,
        priceInclVAT,
        totalAmountExclVAT,
        paymentAmount,
        withholdingTax,
        discountInclVAT,
        discountCode,
        unit,
        taxPercent,
        withholdingTaxPercent,
      } = paymentUpsertPreSalesOrderData;

      const {
        title: courseTitle,
        totalPrice: productPrice,
        cardImageUrl: productImageUrl,
        SKUStatus: productStatus,
        SKUCategory,
        SKUItems,
        eventTime,
        categories,
        permalink,
        parentProduct,
        stock,
      } = preSalesOrderItems[0] ?? {};

      validateSKUStatus({
        productType: SKUCategory,
        SKUCode: skuCode as string,
        permalink:
          SKUCategory === SKU_CATEGORY.WORKSHOP_BATCH ? parentProduct?.permalink : permalink,
        productStatus,
        storeURL,
      });

      const isShipmentPackage = SKUItems?.some(isContainShipmentProduct) ?? false;

      const workshopEventTimeText =
        SKUCategory === SKU_CATEGORY.WORKSHOP_BATCH
          ? getWorkshopEventTimeText(eventTime)
          : undefined;

      updatePurchaseDetailData({
        courseTitle,
        discountCode,
        discountInclVAT,
        isShipmentPackage,
        paymentAmount,
        priceInclVAT,
        unit,
        productImageUrl:
          SKUCategory === SKU_CATEGORY.WORKSHOP_BATCH
            ? parentProduct?.coverImageUrl
            : productImageUrl,
        productPrice,
        productType: SKUCategory,
        skuCode,
        stock,
        totalAmountExclVAT,
        withholdingTax,
        workshopEventTimeText,
        isVirtualWorkshop:
          SKUCategory === SKU_CATEGORY.WORKSHOP_BATCH ? isVirtualWorkshop(categories) : false,
        taxPercent,
        withholdingTaxPercent,
      });

      setIsPreSalesOrderProcessing(false);
    } catch (error) {
      if (error.message.includes('Access denied!')) {
        goToLoginPage({ loginUrl });
        return;
      }

      if (error.message?.includes('Mismatch customer type')) {
        await updateDiscountCode({ discountCode: undefined, customerType });
        return;
      }

      if (error.message?.includes('discountCode')) {
        await updateDiscountCode({ discountCode: undefined });

        setSearchParams({});
        setIsInvalidDiscountCode(true);

        setIsPreSalesOrderProcessing(false);
        return;
      }

      if (error.message?.includes('not found')) {
        navigate('/not-found');
        return;
      }

      console.error(error.message);
    }
  };

  const updateSelectedPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
      case PAYMENT_METHOD.QR_CODE:
        updatePurchaseDetailData({ paymentMethod: PAYMENT_METHOD.QR_CODE });
        break;
      case PAYMENT_METHOD.ATM:
        updatePurchaseDetailData({ paymentMethod: PAYMENT_METHOD.ATM });
        break;
      case PAYMENT_METHOD.CREDIT_CARD:
        updatePurchaseDetailData({ paymentMethod: PAYMENT_METHOD.CREDIT_CARD });
        break;
      default:
        break;
    }
  };

  const updateDiscountCode = async ({
    discountCode,
    onDiscountCodeInvalid,
    customerType,
  }: {
    discountCode?: string;
    onDiscountCodeInvalid?: () => void;
    customerType?: CUSTOMER_TYPE;
  }) => {
    setIsDiscountCodeValidating(true);
    try {
      const { data } = await paymentPreSalesOrder({
        preSalesOrderItems: [{ SKUCode: skuCode as string, quantity: currentProductQuantity }],
        customerType: customerType ?? currentCustomerType,
        discountCode: discountCode,
      });

      const paymentUpsertPreSalesOrderData = data?.sfFoPaymentUpsertPreSalesOrder;

      if (paymentUpsertPreSalesOrderData) {
        const {
          preSalesOrderItems,
          priceInclVAT,
          discountInclVAT,
          totalAmountExclVAT,
          paymentAmount,
          discountCode,
          withholdingTax,
          taxPercent,
          withholdingTaxPercent,
        } = paymentUpsertPreSalesOrderData;
        const {
          title: courseTitle,
          totalPrice: productPrice,
          SKUCategory,
          stock,
        } = preSalesOrderItems[0] ?? {};
        updatePurchaseDetailData({
          skuCode,
          productType: SKUCategory,
          courseTitle,
          productPrice,
          priceInclVAT,
          discountInclVAT,
          withholdingTax,
          totalAmountExclVAT,
          paymentAmount,
          discountCode,
          stock,
          taxPercent,
          withholdingTaxPercent,
        });
      }

      setCurrentDiscountCode(discountCode);
      setIsDiscountCodeValidating(false);
    } catch (error) {
      if (typeof onDiscountCodeInvalid === 'function') {
        onDiscountCodeInvalid();
      }
      setIsDiscountCodeValidating(false);
    }
  };

  const handlePurchaseFreeProduct = (formUserData) => {
    const { firstname, lastname, email, contactInfo } = userInfo ?? {};

    const userInfoFullName = `${firstname} ${lastname}`;

    const orderDetail = {
      items: [
        {
          SKUCode: skuCode ?? '',
          quantity: 1,
        },
      ],
      paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
      customerFullName: formUserData?.customerFullName ?? userInfoFullName,
      customerAddress: '',
      customerMobile: formUserData?.phoneNumber ?? '',
      customerEmail: formUserData?.email ?? contactInfo?.email ?? email,
    };

    purchaseOrder({
      orderDetail,
      onPurchaseSuccess: () => handleOnPurchaseIsSuccessful(orderDetail),
    });
  };

  const handlePurchaseOrder = ({
    firstName,
    lastName,
    email,
    phoneNumber,
    paymentMethod,
    address,
    amphur,
    district,
    province,
    zipcode,
    companyInfo: _companyInfo,
    customerInfo: _customerInfo,
    postalAddress: _postalAddress,
  }: handlePurchaseOrderInput) => {
    const { priceInclVAT, paymentAmount, discountCode, skuCode, isShipmentPackage } =
      purchaseDetailData;

    const customerFullName = `${firstName} ${lastName}`;

    if (priceInclVAT === 0) {
      handlePurchaseFreeProduct({ customerFullName, email, phoneNumber });
    } else {
      let companyInfo: SFFoPlaceOrderWithOfflineTransactionInputProps['companyInfo'] = undefined;
      let customerInfo: SFFoPlaceOrderWithOfflineTransactionInputProps['customerInfo'] = undefined;
      let postalAddress = '';

      const targetCustomerInfo =
        currentCustomerType === CUSTOMER_TYPE.INDIVIDUAL ? _customerInfo : _companyInfo;
      const { address, district, amphur, province, zipcode, ...otherInfo } =
        targetCustomerInfo || {};
      const addressInfo = {
        address,
        district: amphur,
        subDistrict: district,
        province,
        postalCode: zipcode,
      };

      if (taxInvoiceRequested) {
        const taxInvoiceAddress = formatAddress(addressInfo);
        postalAddress = taxInvoiceAddress;

        if (currentCustomerType === CUSTOMER_TYPE.INDIVIDUAL && 'firstName' in otherInfo) {
          customerInfo = { address: taxInvoiceAddress, addressInfo, ...otherInfo };
        } else if (currentCustomerType === CUSTOMER_TYPE.CORPORATE && 'name' in otherInfo) {
          companyInfo = { address: taxInvoiceAddress, addressInfo, ...otherInfo };
        }

        if (!useTaxInvoiceAddressAsShipmentAddress)
          postalAddress = formatAddress({
            address: _postalAddress?.address,
            district: _postalAddress?.amphur,
            subDistrict: _postalAddress?.district,
            province: _postalAddress?.province,
            postalCode: _postalAddress?.zipcode,
          });
      }

      const shipment = {
        shipmentAddress: {
          fullName: customerFullName,
          phoneNumber: phoneNumber,
          ...addressInfo,
          country: SHIPMENT_COUNTRY.THAILAND,
        },
      };

      const orderDetail = {
        items: [
          {
            SKUCode: skuCode ?? '',
            quantity: currentProductQuantity,
          },
        ],
        customerType: currentCustomerType,
        paymentMethod: paymentAmount === 0 ? PAYMENT_METHOD.CREDIT_CARD : paymentMethod,
        customerFullName,
        customerAddress: postalAddress,
        customerMobile: phoneNumber,
        customerEmail: email,
        discountCode,
        companyInfo: companyInfo,
        customerInfo: customerInfo,
        shipment: isShipmentPackage ? shipment : undefined,
        resultUrl: thankYouPageURL,
        taxInvoiceRequested,
      };

      purchaseOrder({
        orderDetail,
        onPurchaseSuccess: () => handleOnPurchaseIsSuccessful(orderDetail),
      });
    }
  };

  const handlePaymentPreSalesOrderWithDebounce = useCallback(
    debounce((newCustomerType) => {
      handlePaymentPreSalesOrder(newCustomerType);
    }, 500),
    [currentDiscountCode, currentProductQuantity]
  );

  const updateCustomerType = useCallback(
    (newCustomerType) => {
      setCurrentCustomerType(newCustomerType);
      handlePaymentPreSalesOrderWithDebounce(newCustomerType);
    },
    [handlePaymentPreSalesOrderWithDebounce]
  );

  const updateProductQuantity = async (quantity: number) => {
    if (quantity !== currentProductQuantity) {
      setCurrentProductQuantity(quantity);
      await handlePaymentPreSalesOrder(currentCustomerType, quantity);
    }
  };

  const handlePurchase = async (purchaseData: handlePurchaseInput) => {
    const discountCode = purchaseDetailData && purchaseDetailData?.discountCode;

    if (enableAnonymousUser && !isLoggedIn) {
      try {
        const quickRegisterResponseStatus = await handleQuickRegister(
          purchaseData,
          userConsent?.PDPAConsentVersion,
          discountCode
        );
        if (quickRegisterResponseStatus === QUICK_REGISTER_RESPONSE_STATUS.SUCCESS) {
          handlePurchaseOrder(purchaseData);
        }
        return;
      } catch (error) {
        console.error(error);
        setIsPurchaseOrderFailed(true);
        return;
      }
    }

    handlePurchaseOrder(purchaseData);
  };

  useEffect(() => {
    handlePaymentPreSalesOrder();
  }, []);

  return (
    <PaymentContext.Provider
      value={{
        ...purchaseDetailData,
        clearPurchaseOrderStatus,
        customerType: currentCustomerType,
        taxInvoiceRequested,
        useTaxInvoiceAddressAsShipmentAddress,
        discountCodeParamValue,
        handlePurchaseOrder: handlePurchase,
        isCoursePurchasing,
        isDiscountCodeValidating,
        isInvalidDiscountCode,
        isPaymentPreSalesOrderUpdating,
        isPreSalesOrderProcessing,
        isPurchaseOrderFailed,
        productQuantity: currentProductQuantity,
        purchaseOrderFailedMessage,
        setIsInvalidDiscountCode,
        setSearchParams,
        updateCustomerType,
        updateTaxInvoiceRequested: setTaxInvoiceRequested,
        updateTaxInvoiceAddressAsShipmentAddress: setUseTaxInvoiceAddressAsShipmentAddress,
        updateDiscountCode,
        updateProductQuantity,
        updateSelectedPaymentMethod,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

const usePayment = (): IPaymentContext => {
  const context = useContext(PaymentContext);
  if (context === undefined) {
    throw new Error('usePayment must be used within PaymentContext');
  }

  return context;
};

export type { handlePurchaseInput as PurchaseInput };
export { PaymentProvider, usePayment, CUSTOMER_TYPE, SKU_CATEGORY };
