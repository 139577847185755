import { useState, useEffect } from 'react';
import { LoadingModal } from '@skooldio/paas-shared-react-components';

import { usePayment } from '../../Contexts/PaymentProvider';
import { CUSTOMER_TYPE, SKU_CATEGORY } from '../../Contexts/PaymentProvider/PaymentProvider.d';

import PaymentSummaryView from '../../Components/PaymentSummary';
import { sendEvent } from '../../Domain/GoogleAnalytics';
import PurchaseFailedModal from '../../Components/PurchaseFailedModal';
import { useConfig } from '../../Contexts/ConfigContext';

const PaymentSummary = () => {
  const { discountCodeRemark } = useConfig();
  const {
    discountCode: validatedDiscountCode,
    discountCodeParamValue,
    priceInclVAT,
    discountInclVAT,
    paymentAmount,
    withholdingTax,
    updateDiscountCode,
    isCoursePurchasing,
    isPurchaseOrderFailed,
    clearPurchaseOrderStatus,
    isPreSalesOrderProcessing,
    isPaymentPreSalesOrderUpdating,
    isDiscountCodeValidating,
    customerType,
    productQuantity,
    productType,
    purchaseOrderFailedMessage,
    isInvalidDiscountCode,
    setIsInvalidDiscountCode,
    setSearchParams,
    unit,
    taxPercent,
    withholdingTaxPercent,
  } = usePayment();

  const [discountCode, setDiscountCode] = useState(validatedDiscountCode ?? discountCodeParamValue);
  const [isAcceptedOfDiscountPolicy, setIsAcceptedOfDiscountPolicy] = useState<boolean>(false);
  const [discountRemark, setDiscountRemark] = useState<string>('');
  const isDiscountCodeValidated =
    typeof validatedDiscountCode === 'string' && validatedDiscountCode.length > 0;

  useEffect(() => {
    if (!discountCode || !isDiscountCodeValidated) {
      setDiscountRemark('');
      return;
    }

    const { selectedCodeList, remarkText } = discountCodeRemark ?? {};
    const shouldShowRemark =
      selectedCodeList && Array.isArray(selectedCodeList) && selectedCodeList.length > 0;

    if (shouldShowRemark) {
      const uppercaseDC = discountCode.toLocaleUpperCase();
      const isSelectedDiscount = selectedCodeList.includes(uppercaseDC);
      if (isSelectedDiscount && remarkText) {
        setDiscountRemark(remarkText);
      }
    }
  }, [isDiscountCodeValidated, discountCode]);

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event?.target?.value);
  };

  const handleDiscountCodeSubmit = (discountCode) => {
    if (!discountCode) {
      setIsInvalidDiscountCode(true);
      return;
    }
    // TODO: Temporary event for testing GA event.
    sendEvent({ category: `DISCOUNT`, action: 'Purchase', label: 'discount_submit' });

    setIsInvalidDiscountCode(false);

    updateDiscountCode({
      discountCode,
      onDiscountCodeInvalid: () => {
        setIsInvalidDiscountCode(true);
      },
    });
  };

  if (isPreSalesOrderProcessing) {
    return null;
  }

  return (
    <>
      <PaymentSummaryView
        totalPrice={priceInclVAT}
        currentDiscountCode={discountCode}
        isInvalidDiscountCode={isInvalidDiscountCode}
        isDiscountCodeValidated={isDiscountCodeValidated}
        isProductPriceProcessing={isPaymentPreSalesOrderUpdating || isDiscountCodeValidating}
        discountPrice={discountInclVAT}
        paymentAmount={paymentAmount}
        unit={unit}
        withholdingTax={customerType === CUSTOMER_TYPE.CORPORATE ? withholdingTax : 0}
        productQuantity={productType === SKU_CATEGORY.WORKSHOP_BATCH ? productQuantity : undefined}
        discountRemark={discountRemark}
        onDiscountCodeChange={handleDiscountCodeChange}
        onDiscountCodeSubmit={handleDiscountCodeSubmit}
        onDiscountCodeCancel={() => {
          updateDiscountCode({ discountCode: '' });
          setDiscountCode('');
          setSearchParams({});
        }}
        setIsAcceptedOfDiscountPolicy={setIsAcceptedOfDiscountPolicy}
        isAcceptedOfDiscountPolicy={isAcceptedOfDiscountPolicy}
        taxPercent={taxPercent}
        withholdingTaxPercent={withholdingTaxPercent}
      />
      <LoadingModal isOpen={isCoursePurchasing} />
      <PurchaseFailedModal
        open={isPurchaseOrderFailed}
        purchaseOrderFailedMessage={purchaseOrderFailedMessage}
        onModalClose={clearPurchaseOrderStatus}
      />
    </>
  );
};

export default PaymentSummary;
